import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React from 'react';
import './NavigationBar.css';
import logoibspl from "../images/logoibspl.webp"


const NavigationBar=() =>{
 
  return (
    <Navbar  expand="lg"   style={{ minHeight:'5rem' , backgroundColor: '#1c2237' } }>
      <Container className='navbar-container' style={{color:'white'}} >
        <Navbar.Brand href="/" style={{color:'white'}}><img src={logoibspl} className="navbarlogo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:'white'}} />
        <Navbar.Collapse id="basic-navbar-nav" style={{color:'white'}}>
          <Nav className="ms-auto" style={{color:'white'}}>
          <Nav.Link href="/" style={{color:'white'}} className="nav-item" >Home</Nav.Link>
          <Nav.Link href="/about" style={{color:'white'}} className="nav-item">About</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown" className="nav-item" >
              <NavDropdown.Item href="#action/3.2" >Income Tax</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.3" >GST</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" >ROC</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" >Others</NavDropdown.Item>
              </NavDropdown>
             <Nav.Link href="/contact" style={{color:'white'}} className="nav-item">Contact Us</Nav.Link>
             </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;