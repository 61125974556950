/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import Whatsapp from './inc/whatsapp';

const ContactComponent = () => {
  const Wrap=styled.div`
  margin-top:4rem;
  form{ border: 1px solid #BFBFBF;
  border-radius: 5px;
  margin:2rem -1rem 2rem 4rem;
  padding:2rem 2rem 2rem 6rem;
  overflow-x: hidden;
   box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) ;
   Label{
    font-size:1rem !important;

   }
  
 
 }
 
  
  @media  (max-width:768px){form{border: 2px solid lightgray;
  border-radius: 5px;
  margin:1rem;
  padding:1rem;}}
  iframe{ margin:2rem 6rem 2rem 4rem;
  overflow-x: hidden;
  padding:2rem 2rem 2rem 2rem;
  border: 1px solid #BFBFBF;
  border-radius: 5px;
  max-width:90%;
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) ; 

}
  `;
  return (
    <>
    <Wrap>
      <h3 className='contactheading'>Get in Touch</h3>
      <div className='underline mx-auto'></div>
      
     <Form style={{maxWidth:"90%" ,float:'inline-start'}}>
     <Form.Group className="mb-3 " controlId="formBasicName">
        <Form.Label>Your Full Name</Form.Label>
        <Form.Control type="name" placeholder="Enter Full Name" />
      </Form.Group>
      <Form.Group className="mb-3 " controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        <Form.Select aria-label="Default select example"  className="mb-3 ">
        
          <option>Select Service</option>
          <option value="1">ITR filing& Other income tax Compliances</option>
          <option value="2">PAN/Minor PAN allotment/Updation</option>
          <option value="3">TAN Alottment/Updation</option>
          <option value="4">TDS Calculation & Payment</option>
          <option value="5">TDS Return Filing and Other Compliances</option>
          <option value="6">GST Registration/Updation/Concellation</option>
          <option value="7">GST  Return Filing and Other Compliances </option>
          <option value="8">Company/LLP/OPC Incorporation</option>
          <option value="9">Merge and Aquisition</option>
          <option value="10">Winding up and Stricke off</option>
          <option value="11">All ROC Filings and Other Compliances</option>
          <option value="12">Digital Signature Certificate(DSC)</option>
          <option value="10">Accounting and Book keeping</option>
          <option value="11">Trademark Services</option>
          <option value="12">Society/Partnership Firm/Trust Registration and Other Compliances</option>
    </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Write Your Query</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Send
      </Button>
    </Form>
    <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20872.52493346552!2d81.03048635745996!3d26.873466784545872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd7310cf5eac81f6d!2sINFANCY%20BUSINESS%20SOLUTIONS%20PRIVATE%20LIMITED%20(IBSPL)!5e0!3m2!1sen!2sin!4v1665079950674!5m2!1sen!2sin"  
          width="100%" height="500" 
          style={{border:0}}
          allowFullScreen
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade" >
        </iframe>
        <Whatsapp/>
    </Wrap>
    </>
  )
}

export default ContactComponent;
