import React from 'react';
import "./whatspp.css";

const whatsapp = () => {
  return (
    <div>
        <a
        href="https://wa.me/7905876203"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
        </div>
  );
}

export default whatsapp;