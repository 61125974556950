import React from 'react'
import { Link } from 'react-router-dom';
import one from '../images/tax2.webp';

import six from '../images/GST2.webp';
import seven from '../images/DSC4.jpg';


import CardUI from './cardUI'

const Services = () => {
  return (
    <>
    <div className='serviceheading'>Our Services</div>
    <div className='underline mx-auto'></div>
    <div className='container d-flex justify-center'>
      
      <div className='row' >
        <div className='col-md-4'>
          <CardUI imgsrc={one} title="Income Tax" description=" ITR filing& Other income tax Compliances etc"  />
        </div>
        <div className='col-md-4'>
          <CardUI  imgsrc={six} title="GST"  description="GST Registration/Updation/Concellation etc" />
        </div>
        <div className='col-md-4'>
          <CardUI imgsrc={seven} title="ROC"  description="All ROC Filings and Other Compliances etc" />
        </div>
        <div className='servicemore'> <Link to="/about"  className='btn btn-primary  shadow'>Check more services....</Link></div>
      </div>

    </div>
    </>
  )
}

export default Services
