import React from 'react';

import Footer from './inc/footer';
import HeroSection from './HeroSection/HeroSection';
import {  homeObjFour, homeObjOne, homeObjThree } from './HeroSection/HeroSectionData';
import HomeSlider from './homeslider/HomeSlider';

import NavigationBar from './inc/Navigationbar';
import Services from './service/Services';
import SuccessSlider from './successpage/SuccessSlider';
import Whatsapp from './inc/whatsapp';
import Call from './inc/call';
import AboutComponent from './AboutComponent';
import ContactComponent from './ContactComponent';




function Home() {
 return (
    <>
       <NavigationBar/>
       <HomeSlider/>
       <AboutComponent/>
       <Services/>
       
       <ContactComponent/>
       <Whatsapp/>
       <Footer/>
    </>
  );
}

export default Home;