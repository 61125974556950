import React from 'react'
import { Carousel } from 'react-bootstrap';
import one from '../images/DSC5.jpg'
//import * from './images/8.jpg' as one
import two from '../images/DSC4.jpg'
import three from '../images/DSC3.jpg'
import { Helmet } from 'react-helmet-async';
import "./HomeSlider.css";


const HomeSlider = () => {
  return (

    <>
   <Carousel>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={one}
          alt="First slide"
        />
        <Carousel.Caption>
        <h3 className='homeheading'><strong>Healthcare Taxation under GST</strong></h3>
          <p className='homeparagraph'><strong>Goods and service tax is a major factor which has a potential
             to cover the economic base of the country along with its sidelines .</strong></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}> 
        <img
          className="d-block w-100"
          src={two}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3 className='homeheading'><strong>Section 194H – TDS on Brokerage or Commission</strong></h3>
          <p className='homeparagraph'><strong>Section 194H contains guidelines about tax deductions 
          on the amount paid as brokerage or commission. Entities making a payment as commission 
          .</strong></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={three}
          alt="Third slide"
        />

        <Carousel.Caption>
        <h3 className='homeheading'><strong>TDS under Section 194R & Section 194S of Income Tax Act, 1961 </strong></h3>
          <p className='homeparagraph'>
         <strong>The Finance Bill 2022 has proposed two completely new TDS sections, 194R and 194S, in the Income 
          Tax Act, to be made effective from 1.7.2022.</strong>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>



  
   
    </>
   
  );
}

export default HomeSlider