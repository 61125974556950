import img2 from '../images/DSC1.webp';
import img3 from '../images/tax4.webp';
import img4 from '../images/DSC1.webp';
import img5 from '../images/5.jpg';
export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Young Forever Hair replacement Studio',
    headline: 'Wants to boost your confidence ??',
    description:
      'Call for free consultation,Our expert will help you deciding what is best suitable solution for you ',
    
    buttonLabel: 'Call Now',
    imgStart: '',
    img:  img2,
    alt: 'Best Non surgical hair replacement'
  };
  
  export const homeAboutUs = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: '   ',
   
    description:'IBSPL offers you a dynamic Company Secretary,CA service, tax & Law consultancy  for all of your business needs.Take full benefits business and management service, Audit, Company Law, Income tax, Gst, Labor law, Export-Import, Trademark, Startup India.Take your business growth to next level.',

    buttonLabel: 'Call Now',
    
    imgStart: 'start',
    img: img3,
    alt: 'Taxation Services'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Young Forever Hair replacement Studio in delhi',
    headline: 'Wants to boost your confidence ??',
    description:
      'Call for free consultation,  Our expert will help you in deciding whats best suitable for you ',
    buttonLabel: 'Call Now',
   
    imgStart: '',
    img: img4,
    alt: 'Best Non surgical hair replacement'
  };
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Young Forever Hair replacement Studio in delhi',
    headline: 'Wants to boost your confidence ??',
    description:
      'Call for free consultation,our expert will help you in deciding whats best suitable for you ',
    buttonLabel: 'Call Now',
    
    imgStart: 'start',
    img:  img4,
    alt: 'Best Non surgical hair replacement'
  };
  
  