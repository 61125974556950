import React from 'react'
import styled from 'styled-components';

const Footer = () =>{
 
  return(
    <>
    <footer className="page-footer font-small blue pt-4" style={ {backgroundColor: '#1c2237'} }>
      <div className="container-fluid text-center text-md-left" style={{color:'white'}} >
        <div className="row">
            <div className="col-md-4 mt-md-0 mt-3">
                <h5 className="text-uppercase"><strong>IBSPL</strong></h5>
                <p>At Ibspl we provide Taxation services</p>
                <p>Please give us a chance to help you out!!!!</p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0"/>

            <div className="col-md-4 mb-md-0 mb-3">
                <h5 className="text-uppercase"> <strong>Quick Links</strong></h5>
                <ul className="list-unstyled">
                    <li className='footerlink'><a href="#!" style={{color:'white'}}>About Us</a></li>
                    <li className='footerlink'><a href="#!"style={{color:'white'}}>Services</a></li>
                    <li className='footerlink'><a href="#!"style={{color:'white'}}>Contact Us</a></li>
                </ul>
            </div>

            <div className="col-md-4 mb-md-0 mb-3">
                <h5 className="text-uppercase"><strong>Address</strong></h5>
                <ul className="list-unstyled">
                    <li className='footerlink'><strong>Tulip Residency, Flat No-703, </strong></li>
                    <li className='footerlink'><strong> Block-A, Vikalp Khand, Gomti Nagar,  </strong></li>
                   <li className='footerlink'><strong>Lucknow,Uttar Pradesh 226010</strong></li>
                    <li className='footerlink'><a href="tel:8882322766" style={{color:'white'}}>+91-7905876203</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-3">
        <a href="#" style={{color:'white'}}> © 2020 Copyright: IBSPL</a>
    </div>
   </footer>
   </>

  );

} 
   

export default Footer;
