import React from 'react'
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeroSection from './HeroSection/HeroSection';
import { homeAboutUs } from './HeroSection/HeroSectionData';
import Call from './inc/call';

const AboutComponent = () => {
  return (
    <>
      <h3 className='aboutheading'>About IBSPL</h3>
      <div className='underline mx-auto'></div>
      <div> <HeroSection {...homeAboutUs} /> </div>
      <section className='section'>
        <div className="container">
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h3 className='main-heading'>Our Company</h3>
              <div className='underline mx-auto'></div>
              <p>Take full benefits business and management service, Audit, Company Law,
                 Income tax, Gst, Labor law, Export-Import, Trademark, Startup India.Take
                  your business growth to next level.Leverage your business to new heights
                   with Top Business Consultancy adviosry services. <strong>IBSPL</strong> Ca office and Company Secretary office focuses on the important things and improves Business management, Compliance, Filing processes and decisions from setup to survival, using an systematic approach that is business-friendly and easy to adopt.

              </p>
              <button  className='btn btn-warning shadow'><a href="tel:7905876203">Know More</a></button>
            </div>
          </div>
        </div>
    </section>
    <Call/>
    {/*mission vision*/}
    <section className='section bg-c-light border-top'>
        <div className="container">
          <div className='row'>
            <div className='col-md-12 mb-5 text-center'>
              <h3 className='main-heading'>Our Mission,Vision and Values</h3>
              <div className='underline mx-auto'></div>
            </div>
            <div className='col-md-4'>
              <h6 className='who'>Who We Are ?</h6>
              <p>
              Company registration Corporate governance services Various certification under MCA, SEBI, Gst
               handling NCLT matters, Fema compliance
                  
              </p>
            </div>
            <div className='col-md-4'>
              <h6 className='who'>What We Do?</h6>
              <p>
              Advise on the Stock Exchange Board matters Advising on the legal matters Labor law compliances
               Due diligence Internal Audit Secretarial audit Valuation of stocks shares debentures services 
             
              </p>
            </div>
            <div className='col-md-4'>
              <h6 className='who'>Our Mission</h6>
              <p>
              Take full benefits business and management service, Audit, Company Law, Income tax, Gst, Labor law,
               Export-Import, Trademark, Startup India.Take your business growth to next level.
             
              </p>
            </div>
          </div>
        </div>
    </section>
     
    </>
  );
}

export default AboutComponent;
