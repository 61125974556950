import React from 'react'
import AboutComponent from './AboutComponent'
import Footer from './inc/footer'
import NavigationBar from './inc/Navigationbar'

const about = () => {
  return (
    <>
    <NavigationBar/>
    <AboutComponent/>
    <Footer/>
      
    </>
  );
}

export default about;
