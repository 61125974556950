import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';
import { CardGroup, Card, Col, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';
import React from 'react';

import './card-style.css';
import { Link } from 'react-router-dom';
const CardUI = (props: { imgsrc: string | undefined; title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; description: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => {
    
     
  return (
    <>
    
    <div className='card text-center shadow'>
       <div className="container">
       <h4 className='card-title'>{props.title}</h4>
        <img src={props.imgsrc} alt="Hair Wig" className='card-img-top' />
       </div>
       <div className='card-body text-dark'>
        
        <p className='card-text text-dark'>
          {props.description}
        </p>
        <button  className='btn btn-warning shadow'><a href="tel:7905876203">Know More</a></button>
       </div>
    </div>
    </>
  );
}

export default CardUI;
