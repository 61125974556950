import React from 'react'
import {BrowserRouter as Router,Route, Switch } from 'react-router-dom'
import './App.css';



import styled from 'styled-components';
import Home from './Components/home';
import About from './Components/about';
import Contact from './Components/contact';
import Services from './Components/service/Services';
import SuccessSlider from './Components/successpage/SuccessSlider';


//import "bootstrap/dist/css/bootstrap.min.css";


const App=() =>{

  
  return (
<>
  <Router> 
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact}/>
    </Switch>
  </Router>
  </>
  );
}

export default App;
