import React from 'react'
import ContactComponent from './ContactComponent';
import Footer from './inc/footer';
import NavigationBar from './inc/Navigationbar';

const contact = () => {
  return (
    <>
    <NavigationBar/>
    <ContactComponent/>
    <Footer/>
      
    </>
  );
}

export default contact;
